import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

type AdditionalItemsState = {
  additionalItems: string[],
  additionalItemsValue: number
}

const initialState: AdditionalItemsState = {
  additionalItems: [],
  additionalItemsValue: 0
}

const AdditionalItems = createSlice({
  name: 'additionalItems',
  initialState,
  reducers: {
    setAdditionalItem: (state, action: PayloadAction<string[]>) => {
      state.additionalItems = action.payload
    },
    setAdditionalItemsValue: (state, action: PayloadAction<number>) => {
      state.additionalItemsValue = action.payload
    },
    reset: () => initialState
  }
})

export const { setAdditionalItem, setAdditionalItemsValue, reset } = AdditionalItems.actions

export const selectAdditionalItems = (state: RootState) => state.additionalItems

export default AdditionalItems.reducer
