import { useAppSelector } from 'hooks/useStore'
import DemandItem from 'pages/Payment/components/Resume/DemandItem'
import { selectPlans } from 'store/modules/Plans.store'
import { PlanType } from 'types/enums'
import { getImage } from 'utils/images'
import { getColorPlan } from 'utils/plans/helpers'

type SummaryOrderProps = {
  name?: string
  plan?: PlanType
  additionalItem?: string[]
}
type BoxPlanRender = {
  color?: string
  additionalItems?: string[]
}

function filterLeituverso(additionalItems: string[]) {
  if (additionalItems.length === 2) {
    return 4
  }
  if (additionalItems[0] === 'leituverso') {
    return 1
  }
  if (additionalItems[0] === 'leituverso_flap') {
    return 2
  }
}

function MiniRender({ color = 'blue' }: BoxPlanRender) {
  return (
    <div>
      <DemandItem iconColor={color} item="01 livro infantil" />
      <DemandItem iconColor={color} item="Mediação de leitura via App" />
      <DemandItem item="Surpresinhas especiais" />
      <DemandItem item="Ebooks e Audiobooks Exclusivos" />
      <DemandItem item="Descontos na loja online" />
    </div>
  )
}

function UniRender({ color = 'red', additionalItems }: BoxPlanRender) {
  return (
    <div>
      <DemandItem iconColor={color} item="01 livro infantil" />
      {additionalItems && filterLeituverso(additionalItems) === 1 && (
        <DemandItem iconColor={color} item="01 item LeituVerso" />
      )}
      {additionalItems && filterLeituverso(additionalItems) === 2 && (
        <DemandItem iconColor={color} item="01 item LeituVerso Learn" />
      )}
      {additionalItems && filterLeituverso(additionalItems) === 4 && (
        <>
          <DemandItem iconColor={color} item="01 item LeituVerso" />
          <DemandItem iconColor={color} item="01 item LeituVerso Learn" />
        </>
      )}
      <DemandItem iconColor={color} item="Mediação de leitura via App" />
      <DemandItem iconColor={color} item="Surpresinhas especiais" />
      <DemandItem iconColor={color} item="Ebooks e Audiobooks Exclusivos" />
      <DemandItem iconColor={color} item="Descontos na loja online" />
    </div>
  )
}

function DuniRender({ color = 'purple', additionalItems }: BoxPlanRender) {
  return (
    <div>
      <DemandItem iconColor={color} item="02 livros infantis" />
      {additionalItems && filterLeituverso(additionalItems) === 1 && (
        <DemandItem iconColor={color} item="01 item LeituVerso" />
      )}
      {additionalItems && filterLeituverso(additionalItems) === 2 && (
        <DemandItem iconColor={color} item="01 item LeituVerso Learn" />
      )}
      {additionalItems && filterLeituverso(additionalItems) === 4 && (
        <>
          <DemandItem iconColor={color} item="01 item LeituVerso" />
          <DemandItem iconColor={color} item="01 item LeituVerso Learn" />
        </>
      )}
      <DemandItem iconColor={color} item="Mediação de leitura via App" />
      <DemandItem iconColor={color} item="Surpresinhas especiais" />
      <DemandItem iconColor={color} item="Ebooks e Audiobooks Exclusivos" />
      <DemandItem iconColor={color} item="Descontos na loja online" />
    </div>
  )
}

function FlapRender({ color = '[#5B2F80]' }: BoxPlanRender) {
  return (
    <div>
      <DemandItem
        iconColor={color}
        item="01 livro Original Leiturinha em inglês"
      />
      <DemandItem iconColor={color} item="01 Play poster exclusivo" />

      <DemandItem
        iconColor={color}
        item="Acesso a audiobooks exclusivos no App do Clube Flap"
      />
      <DemandItem
        iconColor={color}
        item="Descontos exclusivos na Loja Leiturinha"
      />
    </div>
  )
}

export function SummaryOrder({
  name,
  plan,
  additionalItem
}: SummaryOrderProps) {
  const color = getColorPlan(plan)
  const styleText = `text-${color}`
  const img = plan === PlanType.FLAP ? 'kitFlapImage' : 'kitImage'

  function getRenderPlan(plan?: PlanType) {
    switch (plan) {
      case PlanType.MINI:
        return <MiniRender />
      case PlanType.UNI:
        return <UniRender additionalItems={additionalItem} />
      case PlanType.DUNI:
        return <DuniRender additionalItems={additionalItem} />
      case PlanType.FLAP:
        return <FlapRender />
      default:
        return <></>
    }
  }

  return (
    <div
      className={`mb-1 flex justify-between rounded-lg px-8 py-6 border ${`border-${color}`}`}
    >
      <div>
        <p className={`mb-1 text-sm font-black ${styleText}`}>{name}</p>
        {getRenderPlan(plan)}
      </div>
      <div>
        <img src={getImage(img)} alt="Kit Image" />
      </div>
    </div>
  )
}
