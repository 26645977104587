import { PaymentGateway } from 'types/enums/PaymentGateway'
import { Theme } from 'types/theme'

export const themeLeiturinha: Theme = {
  name: 'leiturinha',
  brand: {
    img: 'logoLeiturinha',
    href: 'https://leiturinha.com.br'
  },
  banner: {
    img: 'bannerLeiturinha'
  },
  loading: {
    img: 'loadingLeiturinha'
  },
  footer:
    '<span class="font-bold">Selecione acima</span> a Leiturinha ideal para sua criança',
  plans: {
    default_period: 12,
    variations: ['adyen', '2025_ELT_VA']
  },
  success: {
    app: {
      text: 'Baixe o App Leiturinha para acompanhar a entrega do seu kit e receber conteúdos exclusivos!',
      button: 'Baixar App Leiturinha',
      link: 'https://leiturinha.com.br/go-to-app'
    },
    text: 'Seu Kit Leiturinha já está em produção! Ele vai chegar pra você em até 15 dias úteis. Os próximos kits devem chegar em até 30 dias úteis após a entrega do anterior.'
  },
  color: {
    primary: {
      name: 'purple',
      hex: '#8700A4',
      bg: 'bg-[#8700A4]',
      text: 'text-[#8700A4]'
    },
    secundary: {
      name: 'purpleTransparent',
      hex: '#8700a433',
      bg: 'bg-[#8700a433]',
      text: 'text-[#8700a433]'
    }
  },
  character: {
    name: 'Bebel',
    img: {
      default: 'avatarBebelPurple',
      error: 'avatarBebelRed',
      success: 'avatarBebelGreen'
    }
  },
  modal: {
    img: {
      hi: 'mascoteHi',
      fly: 'mascoteFly',
      pen: 'mascotePen'
    }
  },
  tooltip: {
    textBefore: 'Preencha seus dados para começar a ',
    text: 'incentivar leitura infantil ',
    textAfter: 'em casa!',
    textTitle: 'Dados do responsável'
  },
  tracking: {
    gtm: 'GTM-K8P6B2',
    ga: 'UA-49380428-1',
    pixel: '568739383248271',
    ads: {
      id: 973082393,
      label: 'QxwBCMfF8gkQmZ6A0AM'
    },
    ga4: 'G-30JY7BKYF6'
  },
  crm: {
    plans: {
      path: '/',
      page: 'Home'
    },
    kids: {
      path: '/subscribe/kids',
      page: 'Leitor'
    },
    sign_in: {
      path: '/subscribe/signup',
      page: 'Dados Pessoais'
    },
    payment: {
      path: '/subscribe/payment',
      page: 'Pagamento'
    },
    success: {
      path: '/subscribe/payment/status/success',
      page: 'Resumo'
    }
  },
  child_data_info:
    'Os dados da sua criança nos ajudam a selecionar os melhores livros para cada fase de desenvolvimento.',
  age_category: {
    hide: false
  },
  order_bump: {
    leituverso: {
      mainText: 'Você também pode gostar',
      subText: 'Com base na idade de',
      box: {
        mainImage: 'learnMar2025',
        title: 'Entre para o LeituVerso!',
        price: '+ R$ 25,90/mês',
        priceInt: 25.9,
        description:
          'Itens colecionáveis dos personagens Leiturinha, exclusivos e inéditos.'
      }
    },
    learn: {
      mainText: 'Você também pode gostar',
      subText: 'Com base na idade de',
      box: {
        mainImage: 'learnSmall',
        title: 'Entre para o LeituVerso Learn!',
        price: '+ R$ 29,90/mês',
        priceInt: 29.9,
        description:
          'Receba livros, atividades e audiobooks em inglês que complementam sua experiência.'
      }
    }
  },
  can_add_child: true,
  modal_message: 'entregar o kit Leiturinha',
  signup_password: false,
  payment: {
    pix: false,
    sbx: true,
    gateway: PaymentGateway.adyen
  },
  legal: {
    terms: 'https://leiturinha.com.br/termos/',
    policy: 'https://leiturinha.com.br/politica_de_privacidade/',
    contract: 'https://leiturinha.com.br/site/contrato/tipo/clube/'
  }
}

export const themeFlap: Theme = {
  name: 'flap',
  brand: {
    img: 'logoFlap',
    href: 'https://flap.leiturinha.com.br'
  },
  banner: {
    img: 'bannerFlap'
  },
  loading: {
    img: 'loadingFlap'
  },
  footer: '',
  plans: {
    default_period: 6,
    variations: ['flap-semestral-cheio-5490-frete-990']
  },
  success: {
    app: {
      text: 'Baixe o App Flap para ter acesso aos audiobooks exclusivos dos assinantes!',
      button: 'Baixar App Flap',
      link: 'https://flap.leiturinha.com.br/download'
    },
    text: 'Seu Kit Flap já está em produção! Ele vai chegar pra você em até 15 dias úteis. Os próximos kits devem chegar em até 30 dias úteis após a entrega do anterior.'
  },
  color: {
    primary: {
      name: 'flap',
      hex: '#5B2F80',
      bg: 'bg-[#5B2F80]',
      text: 'text-[#5B2F80]'
    },
    secundary: {
      name: 'purpleTransparent',
      hex: '#8700a433',
      bg: 'bg-[#8700a433]',
      text: 'text-[#8700a433]'
    }
  },
  character: {
    name: 'Flap',
    img: {
      default: 'avatarFlapPurchase',
      error: 'avatarFlapPurchase',
      success: 'avatarFlapPurchase'
    }
  },
  modal: {
    img: {
      hi: 'mascoteFlap',
      fly: 'mascoteFlap',
      pen: 'mascoteFlap'
    }
  },
  tooltip: {
    textBefore: 'Preencha seus dados para começar a ',
    text: 'incentivar o futuro bilingue ',
    textAfter: 'da sua criança!',
    textTitle: 'Dados do responsável'
  },
  tracking: {
    gtm: 'GTM-K8P6B2',
    ga: 'UA-49380428-1',
    pixel: '568739383248271',
    ads: {
      id: 973082393,
      label: 'QxwBCMfF8gkQmZ6A0AM'
    },
    ga4: null
  },
  crm: {
    plans: {
      path: '/',
      page: 'Home Flap'
    },
    kids: {
      path: '/subscribe/kids',
      page: 'Leitor Flap'
    },
    sign_in: {
      path: '/subscribe/signup',
      page: 'Dados Pessoais Flap'
    },
    payment: {
      path: '/subscribe/payment',
      page: 'Pagamento Flap'
    },
    success: {
      path: '/subscribe/payment/status/success',
      page: 'Resumo Flap'
    }
  },
  child_data_info:
    'Todos os dados preenchidos (tanto os seus, quanto da criança) servem para garantirmos o cadastro atualizado e estão seguros conosco.',
  age_category: {
    hide: true,
    text: 'English Reader'
  },
  can_add_child: false,
  modal_message: 'entregar o kit Flap',
  signup_password: false,
  payment: {
    pix: false,
    sbx: true,
    gateway: PaymentGateway.vindi
  },
  legal: {
    terms: 'https://flap.leiturinha.com.br/terms/',
    policy: 'https://flap.leiturinha.com.br/policy',
    contract: 'https://flap.leiturinha.com.br/contract'
  }
}
